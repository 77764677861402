import { authAPI } from '../../api/auth'
import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType, InferActionsType, BaseThunkType } from '../store'
import { getUserProfile } from './profile_reducer'
const SET_RESPONSE_LOGIN_ERROR = 'SET_RESPONSE_LOGIN_ERROR'
const SET_USER_DATA = 'SET_USER_DATA'
const SET_ERROR_UPDATE_PASSWORD = 'SET_ERROR_UPDATE_PASSWORD'

type InitialstateType = {
  username: string | null
  lang: string | null
  isAuth: boolean
  messages: string | null
  updatePasswordMessageError: string | null
}

let initialstate: InitialstateType = {
  username: null,
  isAuth: false,
  messages: '',
  lang: null,
  updatePasswordMessageError: null,
}

const auth_reducer = (state = initialstate, action: any): InitialstateType => {
  switch (action.type) {
    case SET_USER_DATA:
      const newState = {
        ...state,
        ...action.payload,
      }
      return newState
    case SET_RESPONSE_LOGIN_ERROR: {
      return {
        ...state,
        messages: action.messages,
      }
    }
    case SET_ERROR_UPDATE_PASSWORD: {
      return {
        ...state,
        updatePasswordMessageError: action.messages,
      }
    }
    default:
      return state
  }
}

//type ActionsType = setAuthUserDataType | getCaptchaUrlSuccesType | setResponseLoginErrorMessageType;

type DispatchType = Dispatch<ActionsType>

type ActionsType = InferActionsType<typeof actions>

export type ThunkType = BaseThunkType<ActionsType>

export default auth_reducer

export const actions = {
  setAuthUserData: (username: string | null, lang: string | null, isAuth: boolean) =>
    ({
      type: SET_USER_DATA,
      payload: { username, lang, isAuth },
    } as const),
  setResponseLoginErrorMessage: (messages: string) => ({ type: SET_RESPONSE_LOGIN_ERROR, messages }),
  updatePasswordMessageError: (messages: string) => ({ type: SET_ERROR_UPDATE_PASSWORD, messages }),
}

// export const getAuthUserData = (): ThunkType => async (dispatch: DispatchType) => {
//   let MeData = await authAPI.me()

//   let { id, email, login } = MeData.data
//   if (MeData.resultCode == ResultCode.Success) {
//     dispatch(actions.setAuthUserData(id, login, email, true))
//   }
// }

export const login =
  (
    username: string,
    password: string,
    lang: 'RU' | 'EN',
  ): ThunkAction<Promise<void>, AppStateType, unknown, ActionsType> =>
  async (dispatch) => {
    try {
      let LoginData = await authAPI.login(username, password, lang)
      if (LoginData.result == 'success') {
        dispatch(actions.setAuthUserData(username, lang, true))
        dispatch(getUserProfile(username))
      } else {
        console.error('Произошла ошибка: ', LoginData.message)
      }
    } catch (e: any) {
      if (e.response.status == '401') {
        dispatch(actions.setResponseLoginErrorMessage(e.response.data.errorMessage))
      } else {
        dispatch(actions.setResponseLoginErrorMessage(e.message))
      }
    }
  }

export const logout = (): ThunkAction<Promise<void>, AppStateType, unknown, ActionsType> => async (dispatch) => {
  let LoginData = await authAPI.logout()
  if (LoginData.result == 'success') {
    dispatch(actions.setAuthUserData(null, null, false))
  }
}

export const restore =
  (email: string): ThunkAction<Promise<void>, AppStateType, unknown, ActionsType> =>
  async (dispatch) => {
    let RestoreData = await authAPI.restore(email)
  }

export const updatePassword =
  (oldPassword: string, newPassword: string): ThunkAction<Promise<void>, AppStateType, unknown, ActionsType> =>
  async (dispatch) => {
    let reqPassword = { currentPass: oldPassword, newPass: newPassword }
    let date = await authAPI.updatePassword(reqPassword)
    if (date.result === 'success') {
      dispatch(logout())
    }
    if (date.result == 'failure') {
      dispatch(actions.updatePasswordMessageError(date.result))
    }
  }
