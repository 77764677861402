import { ProfileAPI } from '../../api/profile'
import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { ProfileType } from '../../types/types'
const SAVE_PHOTO_SUCCES = 'SAVE_PHOTO_SUCCES'
const SET_USER_PROFILE = 'SETUSERPROFILE'

let initialstate = {
  profile: null as ProfileType | null,
}

export type InitialstateType = typeof initialstate

const profile_reducer = (state = initialstate, action: ActionsType): InitialstateType => {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return { ...state, profile: action.profile }
    }

    default:
      return state
  }
}

export default profile_reducer

type ActionsType = SetuserprofileType | SavePhotoSuccesType

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type SetuserprofileType = {
  type: typeof SET_USER_PROFILE
  profile: ProfileType
}

export let setuserprofile = (profile: ProfileType): SetuserprofileType => {
  return {
    type: SET_USER_PROFILE,
    profile,
  }
}

type SavePhotoSuccesType = {
  type: typeof SAVE_PHOTO_SUCCES
  photos: string
}

export let getUserProfile =
  (userId: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let profileData = await ProfileAPI.getProfile(userId)
    //console.log('Profile Data: ' + JSON.stringify(profileData, null, 4))
    dispatch(setuserprofile(profileData))
  }

export let updateProfile =
  (
    login: string,
    alias: string[],
    fio: string,
    mail: string,
    lang: string,
    organizationOpt: string[],
    positionOpt: string[],
  ): ThunkType =>
  async (dispatch: DispatchType) => {
    let profileData = await ProfileAPI.updateProfile(login, alias, fio, mail, lang, organizationOpt, positionOpt)
    console.log('Update profile ', profileData)
    dispatch(setuserprofile({ fio, alias, mail, organizationOpt, positionOpt }))
  }
