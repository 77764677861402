import React, { FC, useContext, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { theme } from './theme/theme'
import { Login } from './components/login/login'
import { Header } from './components/header/header'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from './redux/store'
import store from './redux/store'
import { actions, login } from './redux/reducers/auth_reducer'
import { getUserProfile } from './redux/reducers/profile_reducer'
import { LanguageContext } from './constants/lang'
const axios = require('axios')

const App: FC = (props) => {
  // const isFetching = useSelector((store))
  let isLogged = useSelector((store: AppStateType) => store.Auth.isAuth)
  let dispatch = useDispatch()
  const { changeLanguage } = useContext(LanguageContext)
  const { children } = props
  const user = sessionStorage.getItem('store')
  const lang = sessionStorage.getItem('lang')
  if (lang) {
    changeLanguage(lang)
  }
  const parsedUser = user ? JSON.parse(user) : null

  useEffect(() => {
    if (localStorage.getItem('store') !== null) {
      localStorage.removeItem('store')
    }
    if (parsedUser !== null) {
      parsedUser.Auth.isAuth &&
        dispatch(actions.setAuthUserData(parsedUser.Auth.username, parsedUser.Auth.lang, parsedUser.Auth.isAuth))
      dispatch(getUserProfile(parsedUser.Auth.username))
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          {isLogged ? (
            <>
              <Header />
              {React.Children.map(children, (child) => child)}
            </>
          ) : (
            <Login />
          )}
        </CssBaseline>
      </ThemeProvider>
    </>
  )
}

export default App
